<template>
    <div  class="mt-5 mb-4 px-0 fondo-blanco" v-if="tipo.MetodoPago==='CREDITCARD'||tipo.MetodoPago==='DEBITCARD'||tipo.MetodoPago==='DEPOSIT'||tipo.MetodoPago==='TRANSFER'" >
      <v-row
        class="px-2 mx-0"
        style="border-bottom: 1px solid #ddd"
      >
        <v-col
          cols="auto"
          style="width: 5%"
          class="px-0 ml-0 py-0 mt-3"
        >
          <v-btn
            x-small
            class="btn-panel-mas-menos"
            @click="toggleCardItemTarjeta(tipo.TipoElementosId)"
            style="background: #444; padding: 0px"

          >
            <v-icon
              style="font-size: 0.8rem; margin: 0px; padding: 0px"
            >
              {{
                cardStatesItemTarjeta[tipo.TipoElementosId] ? "mdi-minus" : "mdi-plus"
              }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="auto"
          md="11"
          class="px-2 mx-0 font-weight-bold"
          >Datos bancarios para {{ titleCard}}</v-col
        >

      </v-row>
      <v-row
          v-if="cardStatesItemTarjeta[tipo.TipoElementosId]"
          class="px-2 mx-0"
          style="border-bottom: 1px solid #ddd"
      >
          <v-col
            cols="auto"
            md="12"
            class="px-0 ml-0 py-0 mt-1"

          >

                <v-card-text style="">
                <v-alert
                  type="info"
                  outlined
                  dense
                  colored-border
                  color="info"
                  elevation="3"
                >
                  <template v-slot:prepend>
                    <v-icon color="info" style="font-size: 30px"
                      >mdi-information</v-icon
                    >
                  </template>
                  <p
                    class="black--text py-0 my-0 ml-3"
                    style="font-weight: 400; color: #444"
                  >
                    Por favor, verifique cuidadosamente los datos ingresados antes de
                    confirmar el pago con Tarjeta.
                  </p>
                </v-alert>

                <!-- Tabs para Acara y MJ -->
                <v-tabs v-model="tab" background-color="white" grow @change="onTabChange">

                  <v-tab v-if="showTab(tipo.TipoElementosId,2)">ANMAC</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab"   style="min-height: 180px; ">



                  <v-tab-item v-if="showTab(tipo.TipoElementosId,2)" :key="2"  style="min-height: 180px;">
                    <v-row class="mt-4 my-0 py-0">
                       <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Nro. Valor <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                class="ml-2"
                                color="default"
                                style="
                                  height: 18px;
                                  width: 18px;
                                  font-size: 10px;
                                  background-color: #888;
                                "
                              >
                                <i class="fa fa-info" aria-hidden="true"></i>
                              </v-btn>
                            </template>
                            <span>{{titleTooltip}}</span>
                          </v-tooltip>
                        </label>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Metodos de Pago</label>
                      </v-col>

                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">{{fechaLabel}}</label>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">


                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Tarjeta.anmac.nroTarjeta"
                          dense
                          outlined
                          :rules="(tipo.MetodoPago==='CREDITCARD' || tipo.MetodoPago==='DEBITCARD')?[$rulesNumericoMax16]:[ $rulesNumericoMax10 ]"
                          prepend-inner-icon="mdi-numeric"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          :value="titleLabel"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-file-document"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Tarjeta.anmac.vtoTarjeta"
                          dense
                          outlined
                          v-mask="'##/##/####'"
                          placeholder="__/__/____"
                          prepend-inner-icon="mdi-calendar"
                          :filled="fechaDisabled"
                          :disabled="fechaDisabled"
                          :rules="(tipo.MetodoPago==='DEPOSIT' || tipo.MetodoPago==='TRANSFER')?[$rulesFechaMayorAHoy]:[]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0">
                      <v-col cols="8" class="my-0 py-0">
                        <label class="importe-label">Tarjeta / Banco</label>

                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Importe</label>
                      </v-col>
                      <v-col cols="8" v-if="tipo.MetodoPago==='CREDITCARD' || tipo.MetodoPago==='DEBITCARD'" class="my-0 py-0">
                        <v-select
                          :items="creditCardsAnmac"
                          dense
                          outlined
                          hide-details
                          item-text="CreditCardName"
                          item-value="CreditCardCode"
                          v-model="tipo.Tarjeta.anmac.banco"
                          prepend-inner-icon="mdi-bank"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="8" v-else class="my-0 py-0">
                        <v-select
                          :items="banksAnmac"
                          dense
                          outlined
                          hide-details
                          item-text="BankName"
                          item-value="BankCode"
                          v-model="tipo.Tarjeta.anmac.banco"
                          prepend-inner-icon="mdi-bank"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                      <v-text-field
                          value="1"
                          v-model="tipo.Tarjeta.anmac.importe"
                          dense
                          outlined
                          hide-details
                          :filled="importeDisabled"
                          :disabled="importeDisabled"
                          prepend-inner-icon="mdi-currency-usd"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
          </v-col>
      </v-row>
    </div>
</template>

<style scoped>

.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
<script>

export default {
  components: {


  },
  props: {

    tipo: {
      type: Object,
      default: false,
    },
    carritoTiposElementos: {
      type: Array,
      default: false,
    },
    metodosPago: {
      type: Array,
      default: false,
    },


  },
  data() {
    return {

    tab: 0,
    banksAnmac:[],
    creditCardsAnmac:[],
    cardStatesItemTarjeta: [
      true
    ],
    titleCard :'',
    titleLabel :'',
    titleTooltip :'',
    importeDisabled:true,
    fechaLabel:'',
    fechaDisabled:false,
    fechaValue:''
    };
  },
  watch: {

  },

  mounted(){
    this.onTabChange(0);

    this.setImportesTarjetas();

    this.MetodoPagoSeleccionado( );
    // despligo  el componente para que se vea
    this.toggleCardItemTarjeta(5);
  },
  methods: {

    MetodoPagoSeleccionado( ){

      if(this.tipo.MetodoPago==='CREDITCARD')
      {
        this.titleCard = "crédito";
        this.titleLabel = "crédito";
        this.titleTooltip ="Número de la tarjeta";
        this.importeDisabled = true;
        this.fechaLabel="Fecha de Vencimiento";
        this.fechaDisabled = true;
        const hoy = new Date();
        const fechaFormateada = `${hoy.getDate().toString().padStart(2, '0')}/${(hoy.getMonth() + 1).toString().padStart(2, '0')}/${hoy.getFullYear()}`;
        this.tipo.Tarjeta.anmac.vtoTarjeta = fechaFormateada;


      }
      if(this.tipo.MetodoPago==='DEBITCARD')
      {
        this.titleCard = "débito";
        this.titleLabel = "débito";
        this.titleTooltip ="Número de la tarjeta";
        this.importeDisabled = true;
        this.fechaLabel="Fecha de Vencimiento";
        this.fechaDisabled = true;
        const hoy = new Date();
        const fechaFormateada = `${hoy.getDate().toString().padStart(2, '0')}/${(hoy.getMonth() + 1).toString().padStart(2, '0')}/${hoy.getFullYear()}`;
        this.tipo.Tarjeta.anmac.vtoTarjeta = fechaFormateada;

      }
      if(this.tipo.MetodoPago==='DEPOSIT')
      {
        this.titleCard = "depósito";
        this.titleLabel = "depósito";
        this.titleTooltip ="Número de comprobante de transacción";
        this.importeDisabled = false;
        this.fechaLabel="Fecha de Depósito";
        this.fechaDisabled = false;
        this.tipo.Tarjeta.anmac.vtoTarjeta = "";

      }
      if(this.tipo.MetodoPago==='TRANSFER')
      {
        this.titleCard = "transferencia";
        this.titleLabel = "transferencia";
        this.titleTooltip ="Número de transferencia";
        this.importeDisabled = true;
        this.fechaLabel="Fecha  de Depósito";
        this.fechaDisabled = false;
        this.tipo.Tarjeta.anmac.vtoTarjeta = "";
      }


    },

    toggleCardItemTarjeta(indexItem) {
      // Alterna la expansión del ítem dentro de la tarjeta
      this.$set(
        this.cardStatesItemTarjeta,
        indexItem,
        !this.cardStatesItemTarjeta[indexItem]
      );
    },
    setImportesTarjetas(){


        let indiceTipo = this.carritoTiposElementos.findIndex((tipo) => tipo.TipoElementosId === this.tipo.TipoElementosId);

        this.carritoTiposElementos[indiceTipo].Tarjeta.anmac.importe = this.$formatMoney(this.$store.state.carrito.sumasPorTipo[this.tipo.TipoElementosId].subtotalDb[this.$db("ANMAC")]);


    },
    onTabChange(newTab) {

      if((this.tipo.MetodoPago==='DEBITCARD') || (this.tipo.MetodoPago==='CREDITCARD')){
        this.fetchCreditCards('creditCardsAnmac');

      }
      else{
        this.fetchBanks('banksAnmac');
      }



    },
    async fetchBanks(database) {
      try {
         await this.$axiosApi.get(database).then((response) => {

        this[database] = response.data.data.value;

        if(this.tipo.TipoElementosId ==5)
        {
          if(this.tipo.MetodoPago == "TRANSFER")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('T'));
          }
          if(this.tipo.MetodoPago == "DEPOSIT")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('D'));
          }
          if(this.tipo.MetodoPago == "BANKNACION_CHEQUE")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('C'));
          }
        }

       })

      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    },
    async fetchCreditCards(database) {
      try {
         await this.$axiosApi.get(database).then((response) => {
          let records = response.data.data.value;
          if(this.tipo.MetodoPago==='DEBITCARD'){
            this[database] = records.filter(
              (obj) => obj.CompanyID == "D"
            );
          }
          else{
            this[database] = records.filter(
              (obj) => obj.CompanyID == "C"
            );
          }
       })

      } catch (error) {
        console.error('Error fetching credit cards:', error);
      }
    },
    showTab(tipo,tabIndex) {
        const subtotalDb =
          this.$store.state.carrito.sumasPorTipo[tipo]
            .subtotalDb;

        if (tabIndex === 2) {
          return subtotalDb[this.$db("ANMAC")] > 0;
        }
        return false;
      },

  },

};
</script>
